<template>
<div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>
        <!-- 详情 -->
        <div class="main x_between">
            <!-- 左边 -->
            <div class="left" style="flex:1">
                <img :src="url" class="poster">
                <div class="column">
                    <div style="color:#fff;margin-top:30px">{{title}}</div>
                    <div style="color:#797979">日期：{{formatDate1(date)}}  作者：{{author}}</div>
                    <div class="back" @click="backPage">返回列表</div>
                </div>
                
            </div>
            <!-- 右边 -->
            <div class="right" style="flex:2">
                <div class="content" style="white-space: pre-wrap;" >{{content}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
export default {
    name: 'twoPage',
    data(){
        return {
            title:"",
            date:"",
            author:"",
            content:"",
            url:""
            }
    },
    components:{
        topBanner
    },
    mounted(){
        this.title= this.$route.query.title;
        this.findDynamics();

    },
    methods:{
        formatDate1(data){
            let dt = new Date(data)
            return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        },
        backPage(){
            this.$router.go(-1)
        },
        backHome(){
            this.$router.push("/")
        },
        findDynamics(){
            let config = {
                headers : {
                    'Content-Type':'application/json'
                }
            };
            const param={
                title:this.title,
            };
            this.axios.post(this.VUE_APP_BASE_API+'/CompanyDynamics/getDynamic',param,config).then((res)=>{
                if(res.data.resultCode==0){
                    this.tabledatas=[];
                    res.data.resultData.forEach((val)=>{
                        let data={
                            title:val.title,
                            author:val.author,
                            content:val.content,
                            date:val.date
                        }
                        this.tabledatas.push(data);
                    });
                    this.title=this.tabledatas[0].title;
                    this.author=this.tabledatas[0].author;
                    this.content=this.tabledatas[0].content;
                    this.date=this.tabledatas[0].date;
                    this.url="http://www.wkhzz.com:8085/BimomWebSource/"+this.title+".png";
                }else{
                    alert(res.data.resultMsg);
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/home/sixPage/bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.main{
    margin-top: 60px;
    .left{
        font-family: '幼圆 Bold', '幼圆 Regular', '幼圆', sans-serif;
        font-weight: bold;
        font-size: 22px;
        color: #FFFFFF;
        margin-top: 30px;
        position: relative;
        .poster{
            width: 100%;
            border-radius: 10px;
        }
        .column{
            div{
                margin-top: 40px;
            }
            .back{
                position: absolute;
                bottom: 0;
                cursor: pointer;
                color:#797979;
                &:hover{
                    color: #02A7F0;
                }
            }
        }
        
    }
    .right{
        width: 100%;
        height: 700px;
        overflow: auto;
        .content{
            color: #fff;
            font-family: '幼圆', sans-serif;
            padding: 0 40px;
            font-size: 22px;
            line-height: 30px;
        }
    }
}

</style>
